const data = [
    {
        img: '/img/shop-card-img-1.svg',
        name: 'Raffle Eth'
    },
    {
        img: '/img/shop-card-img-2.svg',
        name: 'Raffle Eth'
    },
    {
        img: '/img/shop-card-img-3.svg',
        name: 'Raffle Eth'
    },
    {
        img: '/img/shop-card-img-4.svg',
        name: 'Raffle Eth'
    },
    {
        img: '/img/shop-card-img-4.svg',
        name: 'Raffle Eth'
    },
  ]
  export default data