export const nftObj = {
    sel_nft_id: 0,
    name: null,
    img:
      "",
    traits: [
      { name: "", value: "" },
      { name: "", value: "" },
      { name: "", value: "" },
    ],
    lore: "",
    tokenId: null,
    contractAddress: "",
}